import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import modify from '../views/modify.vue';
import login from '../views/login.vue';
import mine from '../views/mine.vue'
import month from '../views/month.vue'
import all from '../views/all.vue'
import info from '../views/info.vue'
import my from '../views/my.vue'
import modify_info from '../views/modify_info.vue'
import account from "../views/account.vue"
import auth from '../views/auth.vue'


Vue.use(VueRouter);

const routes = [

	{ path: '/', redirect: '/login' },
	{
		path: '/login',
		name: 'login',
		component: login,
	},
	{
		path: '/modify',
		name: 'modify',
		component: modify,
	},
	{
		path: '/modify_info',
		name: 'modify_info',
		component: modify_info,
		meta: {
			requireAuth: true // 判断该路由是否需要登录权限
		}
	},
	{
		path: '/home',
		name: 'Home',
		component: Home,
		meta: {
			requireAuth: true // 判断该路由是否需要登录权限
		}
	},
	{
		path: '/mine',
		name: 'mine',
		component: mine,
		meta: {
			requireAuth: true // 判断该路由是否需要登录权限
		}
	},
	{
		path: '/month',
		name: 'month',
		component: month,
		meta: {
			requireAuth: true // 判断该路由是否需要登录权限
		}
	},
	{
		path: '/all',
		name: 'all',
		component: all,
		meta: {
			requireAuth: true // 判断该路由是否需要登录权限
		}
	},
	{
		path: '/info',
		name: 'info',
		component: info,
		meta: {
			requireAuth: true // 判断该路由是否需要登录权限
		}
	},
	{
		path: '/my',
		name: 'my',
		component: my,
		meta: {
			requireAuth: true // 判断该路由是否需要登录权限
		}
	}, {
		path: '/account',
		name: 'account',
		component: account,
		meta: {
			requireAuth: true // 判断该路由是否需要登录权限
		}
	}, {
		path: '/auth',
		name: 'auth',
		component: auth,
		meta: {
			requireAuth: true // 判断该路由是否需要登录权限
		}
	},
	// {
	// 	path: '*',
	// 	component: Home,
	// 	hidden: true,
	// 	meta: {
	// 		noCache: true,
	// 	},
	// },
	{
		name: '404',
		path: '/404',
		component: () => import('@/views/404.vue'),
	},
];

const router = new VueRouter({
	routes,
	// history模式
	// mode: 'hash',
	// base: '/home/',
});

export default router;
