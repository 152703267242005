<template>
  <div style="height: 100%;background-color: #f7f7f7;">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list v-model="loading" :finished="finished" :finished-text="finisheedText" @load="onLoad">
        <div class="mine">
          <div class="line">
            <van-cell center :title="myInfo.n" size="large" :label="myInfo.p" is-link @click="goMyInfo" />
          </div>
          <div class="line flex">
            <van-cell title-class="left_cell" center :title="myCensus.t" label="总邀请统计(人)" is-link @click="goAll" />
            <div class="line_line"></div>
            <van-cell title-class="left_cell" center :title="myCensus.mt" label="本月邀请统计(人)" is-link @click="goMonth" />
          </div>
          <div class="line box">
            <p>最近7天邀请</p>
            <div class="box_view" v-for="item in list" :key="item.i" @click="goInfo(item.i)">
              <img style="width:44px;height: 44px;border-radius: 44px;"
                :src="item.im ? item.im : require('../assets/image/doctor.png')" alt="" />
              <div class="box_mine">
                <div>
                  <span class="name">{{ item.n }}</span>
                  <span class="option" v-if="item.jt">{{ jobTitle[item.jt].label }}</span>
                </div>
                <p class="type">{{ item.fn }} - {{ item.sn }}</p>
              </div>
              <van-icon style="margin-left: auto" name="arrow" />
            </div>
          </div>
          <div v-if="finished && list.length == 0" class="none_data">
            <img src="../assets/image/quesy.png" alt="">
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
    <van-tabbar route>
      <van-tabbar-item replace to="/home">
        <template #icon="props">
          <svg class="icon" aria-hidden="true">
            <use :xlink:href="props.active ? '#icon-shouy' : '#icon-shouy-dele'"></use>
          </svg>
        </template>
        邀请
      </van-tabbar-item>
      <van-tabbar-item replace to="/mine">
        <template #icon="props">
          <svg class="icon" aria-hidden="true">
            <use :xlink:href="props.active ? '#icon-wd' : '#icon-wd-dele'"></use>
          </svg>
        </template>
        数据
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { ChangePageTitle } from "@/util/title";
import request from "../request/api";
import { jobTitle } from '@/util/options'
export default {
  name: "mine",
  data() {
    return {
      myInfo: {},
      myCensus: {},
      list: [],
      loading: false,
      finished: false, // 还有没有数据
      refreshing: false, // 下拉刷新
      finisheedText: '暂无数据',
      jobTitle
    };
  },
  mounted() {
    this.getMyInfo()
    this.getMyCensus()
    // this.getMySevenList()
  },
  methods: {
    // 上拉加载
    async onLoad() {
      const pop = this.list[this.list.length - 1];
      try {
        await this.getMySevenList(pop ? pop.ct : '')
        this.loading = false;
        this.refreshing = false;
      } catch {
        this.loading = false;
        this.finished = true;
        this.refreshing = false;
      }
    },
    // 下拉刷新
    onRefresh() {
      // 清空列表数据
      this.list = []
      // 展示上拉底部的加载中
      this.finished = false;
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad()
    },

    goAll() {
      this.$router.push('/all')
    },
    goMonth() {
      this.$router.push('/month')
    },
    goInfo(i) {
      this.$router.push({ path: '/info', query: { i } })
    },
    goMyInfo() {
      this.$router.push('/my')
    },
    async getMyInfo() {
      const res = await request.getMyInfo()
      this.myInfo = res
    },
    async getMyCensus() {
      const res = await request.getMyCensus()
      this.myCensus = res
    },
    // 请求7天的列表
    async getMySevenList(st) {
      await request.getMySevenList(st || '').then(res => {
        this.list = st ? this.list.concat(res) : res
        if (res.length == 0) {
          this.finished = true;
          this.finisheedText = '没有更多了'
        }
        if (this.list.length == 0) {
          this.finisheedText = '暂无数据'
        }
      })
    },

  },
  created() {
    ChangePageTitle("个人中心");
  },
};
</script>

<style lang="scss" scoped>
.van-cell::after {
  display: none;
}

body {
  background-color: #f7f7f7;
}

.mine {
  height: 100%;

  .line {
    margin-bottom: 12px;
  }

  .line_line {
    width: 1px;
    height: 50px;
    background: #E6E6E6;
  }

  .flex {
    display: flex;
    align-items: center;
    background-color: #fff;

    .van-cell {
      padding-top: 20px;
      padding-bottom: 16px;
    }
  }

  .left_cell {
    font-size: 28px;
    color: #1b88ff;

    >.van-cell__label {
      margin-top: 10px;
    }
  }

  .box {
    background: #fff;
    padding: 8px 16px 0 16px;

    >p {
      font-size: 14px;
      padding-bottom: 8px;
    }

    .box_view {
      display: flex;
      align-items: center;
      padding: 15px 0;
      border-bottom: 1px solid #ebedf0;

      >img {
        height: 44px;
        width: 44px;
        margin-right: 8px;
      }

      .box_mine {
        >div {
          display: flex;
          align-items: center;

          span {
            display: inline-block;
          }
        }

        .name {
          font-size: 16px;
          margin-right: 12px;
          overflow: hidden;
          /*内容超出后隐藏*/
          text-overflow: ellipsis;
          /* 超出内容显示为省略号 */
          white-space: nowrap;
          /* 文本不进行换行 */
          max-width: 85px;
        }

        .option {
          font-size: 20px;
          transform: scale(.5); // 占位置
          transform-origin: 0;
          background: #d6eaff;
          border-radius: 30px;
          color: #1987fe;
          padding: 8px 16px;
        }

        .type {
          font-weight: 400;
          color: #999999;
          font-size: 12px;
          margin-top: 5px;
        }
      }
    }
  }
}

.van-list {
  padding-bottom: 50px;
}

.none_data {
  text-align: center;
  padding-top: 50px;
}

.van-icon {
  color: #999;
}
</style>
