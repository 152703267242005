<template>
  <div style="overflow: hidden;">
    <p class="title">康圣友医营销推广/登录</p>
    <van-cell-group :border="false">
      <van-field
        class="input"
        clearable
        v-model.trim="name"
        placeholder="输入账号"
        @input="input"
        maxlength="11"
      />
      <van-field
        class="input"
        clearable
        v-model.trim="password"
        :type="pasType"
        placeholder="输入密码"
        @input="input"
        maxlength="16"
      >
        <template #button>
          <div style="margin-left: 15px">
            <van-icon size="18" v-if="pasType" name="closed-eye" @click="showEye" />
            <van-icon size="18" v-else name="eye-o" @click="showEye" />
          </div>
        </template>
      </van-field>
    </van-cell-group>
    <div class="loginBtn">
      <van-button :class="adopt ? 'btn_confirm' : 'btn_none'" round block @click="login" :loading="loading">登录</van-button>
    </div>
    <div class="forget">
        <img src="../assets/image/forget_psw.png" alt="">
        <span style="margin-left: 8px">忘记密码？</span>
        <span @click="goModify" style="color: #1B88FF">修改密码</span>
    </div>
    <div class="tip">
      忘记密码，联系客服<a href="tel:400-6826661" style="color: #1B88FF">400-6826661</a>
    </div>
  </div>
</template>


<script>
import { Toast } from 'vant';
import { ChangePageTitle } from '@/util/title'
import apiService from '../request/api'
export default {
  name: "login",
  data() {
    return {
      pasType: 'password',
      name: "",
      password: "",
      adopt: false,
      loading: false,
    };
  },
  methods:{
    // 展示隐藏密码
    showEye() {
      if(this.pasType){
        this.pasType = ''
      }else {
        this.pasType = 'password'
      }
    },

    // 监听输入事件
    input(e) {
      if(this.name && this.password) {
        this.adopt = true
      }else{
        this.adopt = false
      }
    },

    // 去登录
    async login() {
      if(!this.name) {
        return Toast('请输入登录账号')
      }else if(!/^1[3456789]\d{9}$/.test(this.name)){
        return Toast("请输入正确的手机号");
      }else if(!this.password) {
        return Toast('请输入密码')
      }
      const data = {
        p: this.name,
        a: this.password
      }
      this.loading = true
      
      try {
        const res = await apiService.login(data)
        localStorage.setItem('token', res.t)
        setTimeout(() => {
            this.loading = false
            this.$router.push('/home')
        }, 300);
      } catch {
        this.loading = false;
      }
     
    },
    goModify() {
      this.$router.push('/modify')
    },
  },

  // 设置浏览器标题
  created() {
     ChangePageTitle('登录')
  }
  
};
</script>

<style lang="scss" scoped>
.title {
  margin-top: 63px;
  font-size: 30px;
  text-align: center;
  color: #333;
  font-weight: 500;
}
.van-cell-group {
  margin: 63px 40px 0 40px;
}
.input {
  box-sizing: border-box;
  border-bottom: 1px solid #ebedf0;
  padding-left: 0;
}
.van-cell::after {
  border: 0;
}
.loginBtn {
  margin: 40px  40px 0 40px;
  .btn_confirm {
    background: linear-gradient(180deg, #3ABDFF 0%, #1B88FF 100%);
    color: #fff;
    height: 40px;
  }
  .btn_none {
    background: #E6E6E6;
    color: #fff;
    height: 40px;
  }
}
.forget {
  margin-top: 15px;
  margin-left: 40px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #999;
}
.tip {
  margin-top: 100px;
  margin-left: 40px;
  color: #999;
  font-size: 14px;
}
::v-deep input::placeholder{
    color: #999;
}
</style>